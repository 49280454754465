import React from "react";

const HeaderSocials = () => {
  return (
    <div>
      <div className="header__socials">
        <a href="" target="_blank">
          <i class="fa-brands fa-linkedin-in"></i>
        </a>
        <a href="" target="_blank">
          <i class="fa-brands fa-github"></i>
        </a>
        <a href="" target="_blank">
          <i class="fa-brands fa-docker"></i>
        </a>
        <a href="" target="_blank">
          <i class="fa-brands fa-codepen"></i>
        </a>
        <a href="" target="_blank">
          <i class="fa-brands fa-dribbble"></i>
        </a>
      </div>
    </div>
  );
};

export default HeaderSocials;
