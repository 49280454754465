import React, { useEffect } from "react";

import { Route, Routes } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Homepage/Home";
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import Outsource from "./pages/Outsource/Outsource";
import Price from "./pages/Price/Price";
import Outworks from "./pages/Outworks/Outworks";
import Contact from "./pages/Contact/Contact";
import Portfolio_Benjamin from "./pages/Portfolio/Portfolio_Benjamin";
import Portfolio_Aaron from "./pages/Portfolio/Portfolio_Aaron";

import ScrollReveal from "scrollreveal";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

function App() {
  useEffect(() => {
    const sr = ScrollReveal({
      origin: "top",
      distance: "80px",
      duration: 2000,
      reset: true,
    });
    sr.reveal(
      `
      Navbar,Hero,#about,#about-map,,#about-partner,#services,#network,#website,#advertisement,#outsource,#team,Footer
    `,
      {
        opacity: 0,
        interval: 300,
      }
    );
  });
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/outsource" element={<Outsource />}></Route>
        <Route path="/price" element={<Price />}></Route>
        <Route path="/outworks" element={<Outworks />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/team/benjamin" element={<Portfolio_Benjamin />}></Route>
        <Route path="/team/aaron" element={<Portfolio_Aaron />}></Route>
        <Route path="/*" element={<PageNotFound />}></Route>
      </Routes>
    </div>
  );
}

export default App;
