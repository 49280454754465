import React from "react";

import { useState } from "react";

import "./team_nav.css";

const Team_Navbar = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <nav className="team__nav">
      <a
        href="#"
        onClick={() => setActiveNav("#")}
        className={activeNav === "#" ? "active" : ""}
      >
        <i class="fa-solid fa-house"></i>
      </a>
      <a
        href="#about"
        onClick={() => setActiveNav("#about")}
        className={activeNav === "#about" ? "active" : ""}
      >
        <i class="fa-solid fa-user"></i>
      </a>
      <a
        href="#experience"
        onClick={() => setActiveNav("#experience")}
        className={activeNav === "#experience" ? "active" : ""}
      >
        <i class="fa-solid fa-book"></i>
      </a>
      <a
        href="#services"
        onClick={() => setActiveNav("#services")}
        className={activeNav === "#services" ? "active" : ""}
      >
        <i class="fa-solid fa-briefcase"></i>
      </a>
      <a
        href="#contact"
        onClick={() => setActiveNav("#contact")}
        className={activeNav === "#contact" ? "active" : ""}
      >
        <i class="fa-solid fa-mobile"></i>
      </a>
    </nav>
  );
};

export default Team_Navbar;
