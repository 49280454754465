import React from "react";

// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { data__team__portfolio } from "../../data";

import "./team_portfolio.css";

const Team_Portfolio = () => {
  return (
    <section id="team__portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <Swiper
        className="team__portfolio__container container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data__team__portfolio.map((item) => {
          return (
            <SwiperSlide className="team__portfolio-slide">
              <div className="slide__img">
                <img src={item.img} />
              </div>
              <h5 className="slide__title">{item.title}</h5>
              <small className="slide__desc">{item.desc}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Team_Portfolio;
