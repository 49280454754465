import React from "react";
import Experiences from "../../components/Experiences/Experiences";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import Team_Navbar from "../../components/Navbar/Team_Navbar";
import Team_Servies from "../../components/Services/Team_Servies";
import Team_About from "../../components/Team_About/Team_About";
import Team_Contact from "../../components/Team_Contact/Team_Contact";
import Team_Portfolio from "../../components/Team_Portfolio/Team_Portfolio";

import ben_portrait from "../../assets/images/benjamin.jpg";


import "./portfolio.css";
import Qualification from "../../components/Qualification/Qualification";

const Portfolio_Benjamin = () => {
  return (
    <div className="team__portfolio">
      <Header
        title="Benjamin Le"
        job_title="Fullstack Developer."
        portrait={ben_portrait}
      />
      <Team_Navbar />
      <Team_About
        intro="Hello! My name is Benjamin. My role is manage and operation the
            infratructure of company's IT system, including hardware, software
            and networks. I also enjoy creating things that live on the
            internet. My interest in web development, with extensive knowledge
            and year of experience, working in web technology and Ui/ UX design,
            deliverings quality work."
      />
      <Experiences />     
      <Qualification/>
      <Team_Servies />
      <Team_Portfolio />
      <Team_Contact />
      <Footer />
    </div>
  );
};

export default Portfolio_Benjamin;
