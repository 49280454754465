import ex_bg_1 from "./assets/images/Your_Background_Here-1.png";
import ex_bg_2 from "./assets/images/Your_Background_Here-2.png";
import ex_bg_3 from "./assets/images/Your_Background_Here-3.png";

import ben_portrait from "./assets/images/benjamin.jpg";

export const team_images = [ben_portrait];

export const data__price = [
  {
    id: 0,
    title: "Giới thiệu Sản phẩm/Doanh nghiêp",
    price: "Liên hệ",
    completeDate: "3-4 tuần",
    modules: [
      {
        id: 0,
        name: "Trang chủ",
      },
      {
        id: 1,
        name: "Giới thiệu",
      },
      {
        id: 2,
        name: "Slideshow hình ảnh/ Album ảnh",
      },
      {
        id: 3,
        name: "Tìm kiếm",
      },
      {
        id: 4,
        name: "Tin tức",
      },
      {
        id: 5,
        name: "Tuyển dụng",
      },
      {
        id: 6,
        name: "Thăm dò ý kiến",
      },
      {
        id: 7,
        name: "Banner quảng cáo",
      },
      {
        id: 8,
        name: "Hỗ trợ trực tuyến",
      },
      {
        id: 9,
        name: "Liên hệ",
      },
      {
        id: 10,
        name: "Trang quản trị",
      },
    ],
  },
  {
    id: 1,
    title: "Tin tức",
    price: "Liên hệ",
    completeDate: "3-4 tuần",
    modules: [
      {
        id: 11,
        name: "Trang chủ",
      },
      {
        id: 12,
        name: "Mục tin",
      },
      {
        id: 13,
        name: "Phân loại tin",
      },
      {
        id: 14,
        name: "Thẻ nhãn (tag)",
      },
      {
        id: 15,
        name: "Xem tin theo ngày",
      },
      {
        id: 16,
        name: "Tiện ích",
      },
      {
        id: 17,
        name: "Thăm dò ý kiến",
      },
      {
        id: 18,
        name: "Banner quảng cáo",
      },
      {
        id: 19,
        name: "Hỗ trợ trực tuyến",
      },
      {
        id: 20,
        name: "Liên hệ",
      },
      {
        id: 21,
        name: "Trang quản trị",
      },
    ],
  },
  {
    id: 2,
    title: "Thương mại điện tử (E-commerce)",
    price: "Liên hệ",
    completeDate: "3-4 tuần",
    modules: [
      {
        id: 22,
        name: "Trang chủ",
      },
      {
        id: 23,
        name: "Sản phẩm",
      },
      {
        id: 24,
        name: "Đặt hàng",
      },
      {
        id: 25,
        name: "Tìm kiếm",
      },
      {
        id: 26,
        name: "Hình thức thanh toán",
      },
      {
        id: 27,
        name: "Tiện ích",
      },
      {
        id: 28,
        name: "Thăm dò ý kiến",
      },
      {
        id: 29,
        name: "Banner quảng cáo",
      },
      {
        id: 30,
        name: "Hỗ trợ trực tuyến",
      },
      {
        id: 31,
        name: "Liên hệ",
      },
      {
        id: 32,
        name: "Trang quản trị",
      },
    ],
  },
  {
    id: 3,
    title: "Du lịch - Resort - Khách sạn",
    price: "Liên hệ",
    completeDate: "3-4 tuần",
    modules: [
      {
        id: 33,
        name: "Trang chủ",
      },
      {
        id: 34,
        name: "Giới thiệu",
      },
      {
        id: 35,
        name: "Tin tức",
      },
      {
        id: 36,
        name: "Tìm kiếm nhanh, chi tiết",
      },
      {
        id: 37,
        name: "Hình ảnh",
      },
      {
        id: 38,
        name: "Tiện ích",
      },
      {
        id: 39,
        name: "Thăm dò ý kiến",
      },
      {
        id: 40,
        name: "Banner quảng cáo",
      },
      {
        id: 41,
        name: "Hỗ trợ trực tuyến",
      },
      {
        id: 42,
        name: "Liên hệ",
      },
      {
        id: 43,
        name: "Trang quản trị",
      },
    ],
  },
  {
    id: 4,
    title: "Bất động sản, nhà đất",
    price: "Liên hệ",
    completeDate: "3-4 tuần",
    modules: [
      {
        id: 44,
        name: "Trang chủ",
      },
      {
        id: 45,
        name: "Giới thiệu",
      },
      {
        id: 46,
        name: "Tin tức",
      },
      {
        id: 47,
        name: "Phân mục bất động sản",
      },
      {
        id: 48,
        name: "Phân loại bất động sản",
      },
      {
        id: 49,
        name: "Phân nhóm bất động sản, dự án",
      },
      {
        id: 50,
        name: "Tìm kiếm nhanh, chi tiết",
      },
      {
        id: 51,
        name: "Tiện ích",
      },
      {
        id: 52,
        name: "Thăm dò ý kiến",
      },
      {
        id: 53,
        name: "Banner quảng cáo",
      },
      {
        id: 54,
        name: "Hỗ trợ trực tuyến",
      },
      {
        id: 55,
        name: "Liên hệ",
      },
      {
        id: 56,
        name: "Trang quản trị",
      },
    ],
  },
];

export const data__team__portfolio = [
  {
    id: 0,
    img: ex_bg_1,
    title: "Lorem Ipsum",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    id: 1,
    img: ex_bg_2,
    title: "Lorem Ipsum",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    id: 2,
    img: ex_bg_3,
    title: "Lorem Ipsum",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
];

export const data__ben__experiences = [
  {
    id: 0,
    title: "Frontend Development",
    items: [
      {
        id: 0,
        name: "HTML",
        desc: "Experienced",
      },
      {
        id: 1,
        name: "CSS",
        desc: "Intermediate",
      },
      {
        id: 2,
        name: "JavaScript",
        desc: "Experienced",
      },
      {
        id: 3,
        name: "Boostrap",
        desc: "Experienced",
      },
      {
        id: 4,
        name: "React",
        desc: "Experienced",
      },
    ],
  },
  {
    id: 1,
    title: "Backend Development",
    items: [
      {
        id: 5,
        name: "NodeJS",
        desc: "Experienced",
      },
      {
        id: 6,
        name: "MongoDB",
        desc: "Intermediate",
      },
      {
        id: 7,
        name: "PHP",
        desc: "Experienced",
      },
      {
        id: 8,
        name: "MySQL",
        desc: "Experienced",
      },
      {
        id: 9,
        name: "Python",
        desc: "Experienced",
      },
    ],
  },
  {
    id: 2,
    title: "UX/UI Design",
    items: [
      {
        id: 10,
        name: "Figma",
        desc: "Experienced",
      },
      {
        id: 11,
        name: "UX",
        desc: "Experienced",
      },
      {
        id: 12,
        name: "Photoshop",
        desc: "Experienced",
      },
      {
        id: 13,
        name: "Illustrator",
        desc: "Experienced",
      },
      {
        id: 14,
        name: "Corel",
        desc: "Experienced",
      },
    ],
  },
  {
    id: 3,
    title: "Network System",
    items: [
      {
        id: 15,
        name: "Window server",
        desc: "Experienced",
      },
      {
        id: 16,
        name: "Security Data & ...",
        desc: "Experienced",
      },
      {
        id: 17,
        name: "ELV sytstem",
        desc: "Experienced",
      },
      {
        id: 18,
        name: "Network config ...",
        desc: "Experienced",
      },
    ],
  },
];

export const data__ben__qualification = [
  {
    id: 0,
    title: "Accounting",
    subtitle: "Nha Trang University",
    year: "2000 - 2005",
  },
  {
    id: 1,
    title: "MCSA",
    subtitle: "",
    year: "2018 - 2019",
  },
  {
    id: 2,
    title: "MCSP, MCSE",
    subtitle: "",
    year: "2021 - 2021",
  },
  {
    id: 3,
    title: "Web Design & development",
    subtitle: "",
    year: "2020 - 2021",
  },
];
