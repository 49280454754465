import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import SubHeading from "../../components/subHeading/SubHeading";

import "./price.css";

const Price = () => {
  return (
    <>
      <Navbar />
      <SubHeading title="Bảng giá dịch vụ thiết kế Website" />
      <h1 className="gradient-text" style={{ paddingTop: "6rem" }}>
        Bảng giá dịch vụ thiết kế Website của chúng tôi.
      </h1>
      <section className="price__container container grid">
        <div className="price__card flip__card">
          <div className="price__card-cover">
            <h2 className="gradient_text_1 card__title">
              Website <br />
              Giới thiệu Sản phẩm/Doanh nghiêp
            </h2>
            <p className="card__price">
              Liên hệ
              {/* <i class="fa-solid fa-dong-sign"></i> */}
            </p>
            <p className="card__desc">Thời gian hoàn thành: 3-4 tuần</p>
          </div>
          <div className="price__card-details">
            <h2>Các Module bao gồm:</h2>
            <p>
              <i class="fa-solid fa-check"></i>
              Trang chủ
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Giới thiệu
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Slideshow hình ảnh/ Album ảnh
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Tìm kiếm
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Tin tức
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Tuyển dụng
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Thăm dò ý kiến
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Banner quảng cáo
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Hỗ trợ trực tuyến
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Liên hệ
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Trang quản trị
            </p>
          </div>
        </div>
        <div className="price__card flip__card">
          <div className="price__card-cover">
            <h2 className="gradient_text_1 card__title">
              Website <br />
              Tin tức
            </h2>
            <p className="card__price">
              Liên hệ
              {/* <i class="fa-solid fa-dong-sign"></i> */}
            </p>
            <p className="card__desc">Thời gian hoàn thành: 3-4 tuần</p>
          </div>
          <div className="price__card-details">
            <h2>Các Module bao gồm:</h2>
            <p>
              <i class="fa-solid fa-check"></i>
              Trang chủ
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Mục tin
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Tin tức
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Phân loại tin
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Thẻ nhãn (tag)
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Xem tin theo ngày
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Banner quảng cáo
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Hỗ trợ trực tuyến
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Thăm dò ý kiến
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Tiện ích
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Liên hệ
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Trang quản trị
            </p>
          </div>
        </div>
        <div className="price__card flip__card">
          <div className="price__card-cover">
            <h2 className="gradient_text_1 card__title">
              Website <br />
              Thương mại điện tử (E-commerce)
            </h2>
            <p className="card__price">
              Liên hệ
              {/* <i class="fa-solid fa-dong-sign"></i> */}
            </p>
            <p className="card__desc">Thời gian hoàn thành: 3-4 tuần</p>
          </div>
          <div className="price__card-details">
            <h2>Các Module bao gồm:</h2>
            <p>
              <i class="fa-solid fa-check"></i>
              Trang chủ
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Sản phẩm
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Giỏ hàng
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Đặt hàng
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Tìm kiếm
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Banner quảng cáo
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Hỗ trợ trực tuyến
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Hình thức thanh toán
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Thăm dò ý kiến
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Tiện ích
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Liên hệ
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Trang quản trị
            </p>
          </div>
        </div>
        <div className="price__card flip__card">
          <div className="price__card-cover">
            <h2 className="gradient_text_1 card__title">
              Website <br />
              Du lịch - Resort - Khách sạn
            </h2>
            <p className="card__price">
              Liên hệ
              {/* <i class="fa-solid fa-dong-sign"></i> */}
            </p>
            <p className="card__desc">Thời gian hoàn thành: 3-4 tuần</p>
          </div>
          <div className="price__card-details">
            <h2>Các Module bao gồm:</h2>
            <p>
              <i class="fa-solid fa-check"></i>
              Trang chủ
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Giới thiệu
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Tin tức
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Tìm kiếm nhanh, chi tiết
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Banner quảng cáo
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Hỗ trợ trực tuyến
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Thăm dò ý kiến
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Tiện ích
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Liên hệ
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Trang quản trị
            </p>
          </div>
        </div>
        <div className="price__card flip__card">
          <div className="price__card-cover">
            <h2 className="gradient_text_1 card__title">
              Website <br />
              Bất động sản, nhà đất
            </h2>
            <p className="card__price">
              Liên hệ
              {/* <i class="fa-solid fa-dong-sign"></i> */}
            </p>
            <p className="card__desc">Thời gian hoàn thành: 3-4 tuần</p>
          </div>
          <div className="price__card-details">
            <h2>Các Module bao gồm:</h2>
            <p>
              <i class="fa-solid fa-check"></i>
              Trang chủ
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Giới thiệu
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Phân mục bất động sản
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Phân loại bất động sản
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Phân nhóm bất động sản, dự án
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Tìm kiếm nhanh, chi tiết
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Tin tức
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              PDownload
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Hỗ trợ trực tuyến
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Thăm dò ý kiến
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Tiện ích
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Liên hệ
            </p>
            <p>
              <i class="fa-solid fa-check"></i>
              Trang quản trị
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Price;
