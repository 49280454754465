import React from "react";

import SubHeading from "../../components/subHeading/SubHeading";

import server from "../../assets/svg/server.svg";
import cloud_server from "../../assets/svg/cloud-server.svg";
import cloud_server_1 from "../../assets/images/cloud-network.png";
import cctv from "../../assets/svg/cctv-c.svg";
import ad from "../../assets/svg/ad.svg";
import ad_1 from "../../assets/images/bullhorn.png";
import ad_2 from "../../assets/images/design-thinking.png";
import ad_3 from "../../assets/images/social-media.png";
import ad_4 from "../../assets/images/advertising.png";
import network_1 from "../../assets/svg/network-1.svg";
import network from "../../assets/svg/network.svg";
import phone from "../../assets/svg/phone.svg";
import TV from "../../assets/svg/television.svg";
import web from "../../assets/svg/web.svg";
import web_1 from "../../assets/svg/web-1.svg";
import web_2 from "../../assets/images/coding.png";
import web_3 from "../../assets/images/illustration.png";
import wifi from "../../assets/svg/wifi.svg";
import wifi_1 from "../../assets/svg/wifi-1.svg";

import "./services.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";


const Services = () => {
  return (
    <>
    <Navbar/>
      <SubHeading title='Lĩnh vực hoạt động'/>
      <section className="network" id="network">
        <h1 className="gradient-text">
          Tư vấn thiết kế, triển khai, hệ thống mạng
        </h1>
        <div className="services__container container grid">
          <div className="service__content">
            <img src={server} />
            <h2>Server</h2>
            <p>Tư vấn giải pháp, cấu hình, cài đặt Server</p>
          </div>
          <div className="service__content">
            <img src={wifi_1} />
            <h2>Hệ thống Wifi</h2>
            <p>Thiết kế và triển khai Hệ thống Wifi</p>
          </div>
          <div className="service__content">
            <img src={network} />
            <h2>Hệ thống Mạng nội bộ</h2>
            <p>Thiết kế và triển khai Hệ thống mạng nội bộ văn phòng</p>
          </div>
          <div className="service__content">
            <img src={cctv} />
            <h2>Hệ thống Camera</h2>
            <p>Thiết kế và triển khai Hệ thống Camera giám sát an ninh</p>
          </div>
          <div className="service__content">
            <img src={TV} />
            <h2>Hệ thống Truyền hình Internet</h2>
            <p>Thiết kế và triển khai Hệ thống truyền hình, TV thông minh</p>
          </div>
          <div className="service__content">
            <img src={phone} />
            <h2>Hệ thống Tổng đài</h2>
            <p>Thiết kế và triển khai Hệ thống tổng đài</p>
          </div>
        </div>
      </section>
      <section className="website" id="website">
        <h1 className="gradient-text">
          Tư vấn thiết kế website và dịch vụ lưu trữ website
        </h1>
        <div className="services__container container grid">
          <div className="service__content">
            <img src={web_3} />
            <h2>Website</h2>
            <p>Thiết kế và Phát triển Website</p>
          </div>
          <div className="service__content">
            <img src={web_2} />
            <h2>Dịch vụ Website</h2>
            <p>Tư vấn giải pháp Quản trị và hỗ trợ dịch vụ bảo trì Website</p>
          </div>
          <div className="service__content">
            <img src={cloud_server_1} />
            <h2>Dịch vụ lưu trữ Website</h2>
            <p>Cung cấp các dịch vụ lưu trữ Hosting Website</p>
          </div>
        </div>
      </section>
      <section className="advertisement" id="advertisement">
        <h1 className="gradient-text">Dịch vụ thiết kế logo, quảng cáo</h1>
        <div className="services__container container grid">
          <div className="service__content">
            <img src={ad_2} />
            <h2>Thiết kế Logo</h2>
            <p>Dịch vụ thiết kế Logo</p>
          </div>
          <div className="service__content">
            <img src={ad_4} />
            <h2>Thiết kế Banner</h2>
            <p>Dịch vụ thiết kế banner, bảng quảng cáo</p>
          </div>
          <div className="service__content">
            <img src={ad_1} />
            <h2>Dịch vụ quảng cáo, chạy Top view</h2>
            <p>
              Cung cấp dịch vụ up view cho website trên google search, chạy
              quảng cáo trên các nền tảng: Facebook, Zalo, ...
            </p>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default Services;
