import React from "react";
import SubHeading from "../../components/subHeading/SubHeading";

import coffee1 from "../../assets/images/work__demo/coffee.png";
import coffee2 from "../../assets/images/work__demo/coffee1.png";
import plant from "../../assets/images/work__demo/plant.png";
import petshop from "../../assets/images/work__demo/petshop.png";
import streetfood from "../../assets/images/work__demo/streetfood.png";

import "./outworks.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const Outworks = () => {
  return (
    <>
    <Navbar/>
      <SubHeading title="Những dự án Bảo tín đã thực hiện" />
      <h1 className="gradient_text_1" style={{paddingTop:"6rem"}}>
        Những trang web được phát triền bởi chúng tôi
      </h1>
      <section className="outworks__container container grid">
        <div className="outwork__content">
          <img src={coffee1} />
          <a href="https://coffee.baotinsolutions.com/" target="_blank">
            Trang web giới thiệu quán cà phê nhỏ
          </a>
          <p>
            Trang web được thiết kế cho mô hình quán cà phê vừa và nhỏ, dùng để
            giới thiệu quán, thông tin liên hệ và menu
          </p>
        </div>
        <div className="outwork__content">
          <img src={coffee2} />
          <a href="https://coffee1.baotinsolutions.com" target="_blank">
            Trang web giới thiệu quán cà phê nhỏ
          </a>
          <p>
            Trang web được thiết kế cho mô hình quán cà phê vừa và nhỏ, dùng để
            giới thiệu quán, thông tin liên hệ và menu
          </p>
        </div>
        <div className="outwork__content">
          <img src={plant} />
          <a href="https://plant.baotinsolutions.com" target="_blank">
            Trang web về các tiệm cây cảnh
          </a>
          <p>
            Nếu bạn cần một website không quá lớn và có thể giới thiệu được cây
            cảnh, đây chính là trang web mình cần
          </p>
        </div>
        <div className="outwork__content">
          <img src={petshop} />
          <a href="https://petshop.baotinsolutions.com/" target="_blank">
            Website phong cách hiện đại nhất của bảo tín
          </a>
          <p>
            Thật khó khi có thể giới thiệu nhiều sản phẩm nhưng sử dụng các mạng
            xã hội thì bài viết và các dịch vụ của mình sẽ bị trôi xuống dưới,
            website sẽ giải quyết cho bạn.
          </p>
        </div>
        <div className="outwork__content">
          <img src={streetfood} />
          <a href="https://streetfood.baotinsolutions.com" target="_blank">
            Mlem Mlem sẽ gửi đến tất cả mọi người
          </a>
          <p>
            Website đơn giản nhưng đầy đủ thông tin và chức năng cho 1 quán ăn
            nhỏ
          </p>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default Outworks;
