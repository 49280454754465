import React from 'react'

import './team_services.css'

const Team_Servies = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>
      <div className="container team__services__container">
        <article className="team__service">
          <div className="team__service__head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className="team__service__list">
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>              
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
          </ul>
        </article>
        <article className="team__service">
          <div className="team__service__head">
            <h3>Wen Developments</h3>
          </div>
          <ul className="team__service__list">
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
          </ul>
        </article>
        <article className="team__service">
          <div className="team__service__head">
            <h3>Content Creation</h3>
          </div>
          <ul className="team__service__list">
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
            <li>
              <i class="fa-solid fa-check team__service__list-icon"></i>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Team_Servies