import React from 'react'
import { Link } from 'react-router-dom'

import './pagenotfound.css'

const PageNotFound = () => {
  return (
    <section className='page__404 grid'>
        <div className="page__404__container container">
            <div className="page__404-scene" id="scene" data-hover-only='false'>
                <div className="circle" data-depth='1.2'></div>
                <div className="one" data-depth='0.9'>
                    <div className="p404__content">
                        <span className="piece"></span>
                        <span className="piece"></span>
                        <span className="piece"></span>
                    </div>
                </div>
                <div className="two" data-depth='0.8'>
                    <div className="p404__content">
                        <span className="piece"></span>
                        <span className="piece"></span>
                        <span className="piece"></span>
                    </div>
                </div>
                <div className="three" data-depth='0.4'>
                    <div className="p404__content">
                        <span className="piece"></span>
                        <span className="piece"></span>
                        <span className="piece"></span>
                    </div>
                </div>
                <p className="p404" data-depth='.5'>404</p>
                <p className="p404" data-depth='.1'>404</p>
            </div>
            <div className="page__404-text">
                <article>
                    <p>Uh Oh !!! Looks like you lost<br/>
                    Go back to Homepage</p>
                    <Link to='/' className='btn__back'>
                        Go Back
                    </Link>
                </article>
            </div>
        </div>
    </section>
  )
}

export default PageNotFound