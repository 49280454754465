import React from "react";

import { data__ben__experiences } from "../../data";

import "./experiences.css";

const Experiences = () => {
  return (
    <section id="experience">
      <h5>What skill i have</h5>
      <h2>My Experiences</h2>

      <div className="container experience__container">
        {data__ben__experiences.map((item) => {
          return (
            <div className="experience__data" key={item.id}>
              <h3>{item.title}</h3>
              <div className="experience__content">
                <article className="experience__details">
                  {item.items.slice(0, 5).map((detail) => {
                    return (
                      <div>
                        <i class="fa-solid fa-check experience__details-icon"></i>
                        <div key={detail.id}>
                          <h4>{detail.name}</h4>
                          <small className="text-light">{detail.desc}</small>
                        </div>
                      </div>
                    );
                  })}
                </article>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Experiences;
