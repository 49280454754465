import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.png";

import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container grid">
        <Link to="/" className="footer__logo">
          <img src={logo} />
        </Link>
        <div className="footer__content">
          <h2>Thông tin liên hệ</h2>
          <hr />
          <ul>
            <li>
              <i class="fa-solid fa-map"></i>36.39 Sth Đường 3A KĐT Lê Hồng
              Phong 2, TP Nha Trang Tỉnh Khánh Hoà Mobile
            </li>
            <li>
              <i class="fa-solid fa-mobile-button"></i>+84 935 909 788
            </li>
            <li>
              <i class="fa-solid fa-mobile-button"></i>+84 983 379 298
            </li>
            <li>
              <i class="fa-solid fa-envelope"></i>info@baotinsolutions.com
            </li>
            <li>
              <i class="fa-solid fa-globe"></i>www.baotinsolutions.com
            </li>
          </ul>
        </div>
      </div>
      <div className="footer__copyright">
        <small>&copy; Bảo tín Solution 2022 || All rights reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
