import React from "react";

import portrait from "../../assets/images/benjamin.jpg";

import "./team_about.css";

const Team_About = ({intro}) => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <h1>What i do now</h1>
          <div className="about__me-content">
            <p class="about_description">
              Crazy full stack developer who wants to explore every tech stack
            </p>
            <ul>
              <li>
                <i class="fa-brands fa-html5"></i>html5
              </li>
              <li>
                <i class="fa-brands fa-css3-alt"></i>css3
              </li>
              <li>
                <i class="fa-brands fa-sass"></i>sass
              </li>
              <li>
                <i class="fa-brands fa-js-square"></i>js script
              </li>
              <li>
                <i class="fa-brands fa-react"></i>reactjs
              </li>
              <li>
                <i class="fa-brands fa-wordpress"></i>wordpress
              </li>
            </ul>
            <ul>
              <li>
                <i class="fa-brands fa-node-js"></i>nodejs
              </li>
              <li>
                <i class="fa-brands fa-npm"></i>npm
              </li>
              <li>
                <i class="fa-solid fa-database"></i>sql-database
              </li>
              <li>
                <i class="fa-solid fa-fire-flame-curved"></i>firebase
              </li>
              <li>
                <i class="fa-brands fa-docker"></i>docker
              </li>
            </ul>
            <p className="about__me-desc">
              <i class="fa-solid fa-circle"></i>
              Develop highly interactive Front end / User Interfaces for your
              web and mobile applications
            </p>
            <p className="about__me-desc">
              <i class="fa-solid fa-circle"></i>
              Progressive Web Applications ( PWA ) in normal and SPA Stacks
            </p>
            <p className="about__me-desc">
              <i class="fa-solid fa-circle"></i>
              Integration of third party services such as Firebase/ AWS /
              Digital Ocean
            </p>
          </div>
        </div>
        <div className="team__about__content">
          <div className="about__cards">
            <article className="about__card">
              <i class="fa-solid fa-award about__icon"></i>
              <h5>Experience</h5>
              <small>5+ Year Working</small>
            </article>
            <article className="about__card">
              <i class="fa-solid fa-users about__icon"></i>
              <h5>Clients</h5>
              <small>100+ Worldwide</small>
            </article>
            <article className="about__card">
              <i class="fa-solid fa-book about__icon"></i>
              <h5>Projects</h5>
              <small>150+ Completed</small>
            </article>
          </div>
          <p>
            {intro}
          </p>
          <a href="#contact" className="btn__about btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default Team_About;
