import React from "react";

import SubHeading from "../../components/subHeading/SubHeading";

import server from "../../assets/images/server.png";
import lan from "../../assets/images/lan.png";
import wifi from "../../assets/svg/wifi-1.svg";
import web from "../../assets/images/web-development.png";

import "./outsource.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const Outsource = () => {
  return (
    <>
    <Navbar/>
      <SubHeading title="Các dịch vụ IT Outsources." />
      <section className="it_outsource">
        <div className="outsource__container container">
          <h1 className="gradient-text">
            Dịch vụ cung cấp IT Outsource của Bảo tín Solutions
          </h1>
          <div className="outsource__text">
            <p>
              Trong thời đại 4.0 hiện nay, mọi doanh nghiệp (Khách sạn, Nhà
              hàng, Công ty, Kho xưởng) đều có hệ thống mạng nội bộ văn phòng và
              sử dụng wifi trong tòa nhà, thế nhưng không phải mọi cơ sở tòa nhà
              đều có bộ phận IT hoặc IT không được đào tạo chuyên nghiệp để có
              thể xử lý hiệu quả mọi tình huống xảy ra. Vi vậy, Bảo Tín
              Solutions cung cấp cho Quý vị dịch vụ IT Outsource có thể giải
              quyết thỏa mãn tất cả các vấn đề của Khách hàng và bổ sung cho
              Khách hàng những lợi ích:
            </p>
            <p className="indent">
              <i class="fa-solid fa-minus"></i>Tiết kiệm chi phí nhân lực cho
              Doanh nghiệp
            </p>
            <p className="indent">
              <i class="fa-solid fa-minus"></i>Hỗ trợ thêm cho Khách hàng mảng
              thiết kế Đồ họa, thiết kế và Quản lý Website của Khách hàng
            </p>
            <p className="indent">
              <i class="fa-solid fa-minus"></i>Với đội ngũ nhân viên IT dày dặn
              kinh nghiệm về hệ thống và Đặc biệt là các phần mềm về Quản lý
              Khách sạn Doanh nghiệp, chúng tôi đảm bảo bộ máy Quý Khách hàng
              vận hành suôn sẻ
            </p>
            <p className="indent">
              <i class="fa-solid fa-minus"></i>Phù hợp với nhiều đặc thù Doanh
              nghiệp, cùng vời đội ngũ nhân viên và đối tác năng động, chúng tôi
              luôn giải quyết vấn đề của Khách hàng một cách tối ưu nhất
            </p>
          </div>
          <div className="outsource__cards grid">
            <div className="outsource__card">
              <img src={server} />
              <p>
                <i class="fa-solid fa-check"></i>
                giám sát thời gian thực hiệu quả
              </p>
              <p>
                <i class="fa-solid fa-check"></i>
                giám sát từ xa 24/7
              </p>
              <p>
                <i class="fa-solid fa-check"></i>
                thông báo và báo cáo thuận tiện
              </p>
              <p>
                <i class="fa-solid fa-check"></i>
                trực quan hóa dữ liệu
              </p>
            </div>
            <div className="outsource__card">
              <img src={lan} />
              <p>
                <i class="fa-solid fa-check"></i>
                bảo trị hệ thống thường nhật
              </p>
              <p>
                <i class="fa-solid fa-check"></i>
                nâng cấp hệ thống
              </p>
              <p>
                <i class="fa-solid fa-check"></i>
                tối ưu hóa thiết bị
              </p>
              <p>
                <i class="fa-solid fa-check"></i>
                sửa chữa lỗi phát sinh
              </p>
            </div>
            <div className="outsource__card">
              <img src={wifi} />
              <p>
                <i class="fa-solid fa-check"></i>
                Bảo trì mạng wifi
              </p>
              <p>
                <i class="fa-solid fa-check"></i>
                nâng cấp hệ thống wifi
              </p>
              <p>
                <i class="fa-solid fa-check"></i>
                đảm bảo mạng wifi chạy ổn định
              </p>
            </div>
            <div className="outsource__card">
              <img src={web} />
              <p>
                <i class="fa-solid fa-check"></i>
                xây dựng Website
              </p>
              <p>
                <i class="fa-solid fa-check"></i>
                hỗ trợ, quản trị và bảo trì Website
              </p>
              <p>
                <i class="fa-solid fa-check"></i>
                cập nhật Website định kỳ
              </p>
              <p>
                <i class="fa-solid fa-check"></i>
                cơ sở dữ liệu và di chuyển tập tin
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default Outsource;
