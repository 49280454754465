import React from "react";
import Header from "../../components/Header/Header";

import aaron from "../../assets/images/aaron.jpg";

import "./portfolio.css";

import Team_Navbar from "../../components/Navbar/Team_Navbar";
import Team_About from "../../components/Team_About/Team_About";
import Experiences from "../../components/Experiences/Experiences";
import Team_Servies from "../../components/Services/Team_Servies";
import Team_Portfolio from "../../components/Team_Portfolio/Team_Portfolio";
import Team_Contact from "../../components/Team_Contact/Team_Contact";

const Portfolio_Aaron = () => {
  return (
    <div className="team__portfolio">
      <Header title="Aaron Le" job_title="Sales Manager" portrait={aaron} />
      <Team_Navbar />
      <Team_About/>
      <Experiences/>
      <Team_Servies/>
      <Team_Portfolio/>
      <Team_Contact/>
    </div>
  );
};

export default Portfolio_Aaron;
