import React from "react";
import Hero from "../../components/Hero/Hero";
import Navbar from "../../components/Navbar/Navbar";

import about_bg from "../../assets/images/bg_about.png";
import service_bg_1 from "../../assets/images/bg_service_1.png";
import service_bg_2 from "../../assets/images/bg_service_2.png";
import outsource_bg from "../../assets/images/bg_outsource.png";
import team1 from "../../assets/images/benjamin.jpg";
import team2 from "../../assets/images/aaron.jpg";
import team3 from "../../assets/images/khai.jpg";
import team4 from "../../assets/images/vu.jpg";


import "./Home.css";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

const Home = () => {
  return (
    <>
      <Navbar/>
      <Hero />
      <section className="about" id="about">
        <div className="about__container container grid ">
          <div className="about__img">
            <img src={about_bg} />
          </div>
          <div className="about__content">
            <div className="about__content-text">
              <h2 className="gradient-text">Giới thiệu về</h2>
              <h1 className="gradient_text_1">Bảo tín Solutions</h1>
              <p>
                Mỗi doanh nghiệp đều được xây dựng bởi khát vọng cháy bỏng của
                tất cả mọi người: người sáng lập, người lãnh đạo, nhân viên hay
                bất kỳ ai khác đang hàng ngày kề vai sát cánh để tạo lập nên
                những giá trị tốt đẹp cho xã hội. Và chúng tôi tin rằng Bảo Tín
                Solution sẽ là một trong những tập thể đồng hành cùng Khách Hàng
                để mang đến những giá trị khác biệt đó.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* ***** SERVICES SECTION ***** */}
      <section className="services" id="services">
        <h1 className="gradient_text_1">
          Những loại hình dịch vụ của Bảo tín Solution
        </h1>
        <div className="services__container-home container grid">
          <div className="service__content-home">
            <div className="service__img">
              <img src={service_bg_1} />
            </div>
            <div className="service__text">
              <p>
                <i class="fa-solid fa-check-double"></i>
                Cung cấp giải pháp mạng trong các building, văn phòng, kho xưởng
              </p>
              <p>
                <i class="fa-solid fa-check-double"></i>
                Chuẩn hóa hạ tầng, dịch vụ Công nghệ thông tin sẵn có của Khách
                hàng
              </p>
            </div>
          </div>
          <div className="service__content-home">
            <div className="service__img">
              <img src={service_bg_2} />
            </div>
            <div className="service__text">
              <p>
                <i class="fa-solid fa-check-double"></i>
                Tư vấn thiết kế Website, đồ họa, logo quảng cáo
              </p>
              <p>
                <i class="fa-solid fa-check-double"></i>
                Dịch vụ hosting Website, quản trị Website
              </p>
              <p>
                <i class="fa-solid fa-check-double"></i>
                Kinh doanh các dịch vụ trực tuyến, quảng cáo trực tuyến
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* ***** OUTSOURCE SECTION ***** */}
      <section className="outsource" id="outsource">
        <div className="outsource__container container grid">
          <div className="outsource__img">
            <img src={outsource_bg} />
          </div>
          <div className="outsource__text">
            <h1 class="gradient_text_1">
              Dịch vụ <br />
              IT OUTSOURCES
            </h1>
            <p>
              Bảo tín Solutions cung cấp cho Khách hàng đội ngũ nhân viên chuyên
              nghiệp, sẵn sàng online 24/24 để giải quyết mọi vấn để xảy ra với
              hệ thống của Khách hàng
            </p>
            <Link to="/outsource" class="btn o-btn">
              Tìm hiểu thêm
            </Link>
          </div>
        </div>
      </section>

      {/* ***** TEAM SECTION ***** */}
      <section className="team" id="team">
        <h1 className="gradient_text_1">Gặp gỡ Team chúng tôi</h1>
        <div className="team__container container grid">
          <div className="team__content">
            <div className="team__img">
              <img src={team1} />
            </div>
            <div className="team__info">
              <h2>Lê Nguyên Tú</h2>
              <p>Developer</p>
            </div>
            <Link to='/team/benjamin' className="btn team__btn" target="_blank" href="#">
              Xem hồ sơ
              <i class="fa-solid fa-arrow-right"></i>
            </Link>
          </div>
          <div className="team__content">
            <div className="team__img">
              <img src={team2} />
            </div>
            <div className="team__info">
              <h2>Lê Duy Khải</h2>
              <p>Sales Manager</p>
            </div>
            <Link to="/team/aaron" className="btn team__btn" target="_blank" href="#">
              Xem hồ sơ
              <i class="fa-solid fa-arrow-right"></i>
            </Link>
          </div>
          <div className="team__content">
            <div className="team__img">
              <img src={team3} />
            </div>
            <div className="team__info">
              <h2>Lê Phước Khải</h2>
              <p>Chief Security</p>
            </div>
            <Link to='/team/khai' className="btn team__btn" target="_blank" href="#">
              Xem hồ sơ
              <i class="fa-solid fa-arrow-right"></i>
            </Link>
          </div>
          <div className="team__content">
            <div className="team__img">
              <img src={team4} />
            </div>
            <div className="team__info">
              <h2 style={{fontSize:"18px", lineHeight:"1.7"}}>TRƯƠNG HIỀN LUÂN VŨ</h2>
              <p>System Administrator</p>
            </div>
            <Link to='/team/vu' className="btn team__btn" target="_blank" href="#">
              Xem hồ sơ
              <i class="fa-solid fa-arrow-right"></i>
            </Link>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default Home;
