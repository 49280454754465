import React from "react";

import './team_contact.css'

const Team_Contact = () => {
  return (
    <section id="contact">
      <h5>You have a new Project in mind</h5>
      <h2>Contact me now</h2>
      <div className="team__contact__container container grid">
        <div className="team__contact__options">
          <article className="team__contact__option">
            <i class="fa-solid fa-envelope team__contact__option-icon"></i>
            <h4>Email</h4>
            <h5>dummyreact@gmail.com</h5>
            <a href="" target="_blank">
              Send a message
            </a>
          </article>
          <article className="team__contact__option">
            <i class="fa-solid fa-mobile team__contact__option-icon"></i>
            <h4>Phone</h4>
            <h5>+123456789</h5>
            <a href="" target="_blank">
              Call
            </a>
          </article>
          <article className="team__contact__option">
            <i class="fa-solid fa-message team__contact__option-icon"></i>
            <h4>Messenger</h4>
            <h5>+123456789</h5>
            <a href="" target="_blank">
              Send a message
            </a>
          </article>
        </div>
        <form action="">
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
            id=""
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            required
            id=""
          />
          <textarea
            type="text"
            name="message"
            rows="7"
            placeholder="Your Message"
            required
            id=""
          />
          <button type="submit" className="btn btn-primary">
            Send a message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Team_Contact;
