import React from "react";
import './hero.css'

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero__container container">
        <div className="hero__content">
          <h1 className="gradient_text_1">
            <b>Bảo tín </b>Solutions
          </h1>
          <p>
            "Đến với Bảo tín Solutions là đến với những giá trị đích thực về
            Công nghệ thông tin,
            <br />
            là sự lựa chọn nền tảng đúng đắn để phát triển mọi tiêu chí của Công
            ty."
          </p>
        </div>
        <div className="hero__socials">
          <a href="" target="_blank">
            <i class="fa-brands fa-facebook-square"></i>
          </a>
          <a href="" target="_blank">
            <i class="fa-brands fa-linkedin"></i>
          </a>
          <a href="" target="_blank">
            <i class="fa-brands fa-docker"></i>
          </a>
          <a href="" target="_blank">
            <i class="fa-brands fa-github"></i>
          </a>
          <a href="" target="_blank">
            <i class="fa-brands fa-dribbble"></i>
          </a>
          <a href="" target="_blank">
            <i class="fa-brands fa-codepen"></i>
          </a>        
        </div>
      </div>
    </div>
  );
};

export default Hero;
