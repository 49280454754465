import React from "react";

import "./tab.css";

const First__Tab = () => {
  return (
    <div className="first__tab">
      <div class="qualification_data">
        <div>
          <h3 class="qualification_title">Accounting</h3>
          <span class="qualification_subtitle">Nha Trang University</span>
          <div class="qualification_calendar">
            <i class="fa-solid fa-calendar-days"></i> 2000 - 2005
          </div>
        </div>
        <div>
          <span class="qualification_rounder"></span>
          <span class="qualification_line"></span>
        </div>
      </div>
      {/* <!-- Qualification Data 2 --> */}
      <div class="qualification_data">
        <div></div>
        <div>
          <span class="qualification_rounder"></span>
          <span class="qualification_line"></span>
        </div>
        <div>
          <h3 class="qualification_title">MCSA</h3>
          <span class="qualification_subtitle"></span>
          <div class="qualification_calendar">
            <i class="fa-solid fa-calendar-days"></i> 2018 - 2019
          </div>
        </div>
      </div>
      {/* <!-- Qualification Data 3 --> */}
      <div class="qualification_data">
        <div>
          <h3 class="qualification_title">MCSP, MCSE</h3>
          <span class="qualification_subtitle"></span>
          <div class="qualification_calendar">
            <i class="fa-solid fa-calendar-days"></i> 2001 - 2021
          </div>
        </div>
        <div>
          <span class="qualification_rounder"></span>
          <span class="qualification_line"></span>
        </div>
      </div>
      {/* <!-- Qualification Data 4 --> */}
      <div class="qualification_data">
        <div></div>
        <div>
          <span class="qualification_rounder"></span>
          <span class="qualification_line"></span>
        </div>
        <div>
          <h3 class="qualification_title">Web Development</h3>
          <span class="qualification_subtitle"></span>
          <div class="qualification_calendar">
            <i class="fa-solid fa-calendar-days"></i> 2021
          </div>
        </div>
      </div>
    </div>
  );
};

export default First__Tab;
