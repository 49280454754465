import React from 'react'
import CTA from './CTA'
import HeaderSocials from './HeaderSocials'


import './header.css'

const Header = ({title, job_title, portrait}) => {
  return (
    <header className='team__header'>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>{title}</h1>
        <h5 className="text-light">{job_title}</h5>
        <CTA/>
        <HeaderSocials/>
        <div className="portrait">
          <img src={portrait} alt="" />
        </div>
        <a href="#contact" className="scroll__down">Scroll down</a>
      </div>
    </header>
  )
}

export default Header