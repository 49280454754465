import React from 'react'

const Second__Tab = () => {
  return (
    <div className='second__tab'>
        <div class="qualification_data">
        <div>
          <h3 class="qualification_title">IT Officer</h3>
          <span class="qualification_subtitle">Nagar Hotel</span>
          <div class="qualification_calendar">
            <i class="fa-solid fa-calendar-days"></i> 2018 - 2019
          </div>
        </div>
        <div>
          <span class="qualification_rounder"></span>
          <span class="qualification_line"></span>
        </div>
      </div>
      {/* <!-- Qualification Data 2 --> */}
      <div class="qualification_data">
        <div></div>
        <div>
          <span class="qualification_rounder"></span>
          <span class="qualification_line"></span>
        </div>
        <div>
          <h3 class="qualification_title">IT Officer</h3>
          <span class="qualification_subtitle">Nha Trang Horizon Hotel</span>
          <div class="qualification_calendar">
            <i class="fa-solid fa-calendar-days"></i> 2019 - 2021
          </div>
        </div>
      </div>
      {/* <!-- Qualification Data 3 --> */}
      <div class="qualification_data">
        <div>
          <h3 class="qualification_title">IT support</h3>
          <span class="qualification_subtitle">Hiệp hội du lịch Nha Trang</span>
          <div class="qualification_calendar">
            <i class="fa-solid fa-calendar-days"></i> 2021
          </div>
        </div>
        <div>
          <span class="qualification_rounder"></span>
          <span class="qualification_line"></span>
        </div>
      </div>
      {/* <!-- Qualification Data 4 --> */}
      <div class="qualification_data">
        <div></div>
        <div>
          <span class="qualification_rounder"></span>
          <span class="qualification_line"></span>
        </div>
        <div>
          <h3 class="qualification_title">IT - MEP team</h3>
          <span class="qualification_subtitle">Holiday Hotel Prọect</span>
          <div class="qualification_calendar">
            <i class="fa-solid fa-calendar-days"></i> 2022
          </div>
        </div>
      </div>
    </div>
  )
}

export default Second__Tab