import React from "react";

const SubHeading = ({ title }) => {
  return (
    <section className="hero">
      <div className="hero__service__content">
        <h1 className="gradient_text_1">
          <b>Bảo tín </b>Solutions
        </h1>
        <p style={{ fontSize: "1.5rem" }}>{title}</p>
      </div>
    </section>
  );
};

export default SubHeading;
