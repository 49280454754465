import React from "react";

import SubHeading from "../../components/subHeading/SubHeading";

import itech from '../../assets/images/itech.png';
import xiaodu from '../../assets/images/Xiaodu.png';
import hik from '../../assets/images/Hikvision.png';
import kb from '../../assets/images/kbvision.png';
import tuya from '../../assets/images/tuya.png';

import "./about.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";


const About = () => {
  return (
    <>
    <Navbar/>
      <SubHeading title='Giới thiệu về chúng tôi Bảo tín'/>
      <section className="about">
        <h1 className="gradient-text">
          Bảo tín Solutions <br /> Giải pháp cho mọi vấn đề
        </h1>
        <div className="about__content container" id="about-content">
          <p>
            Công ty Bảo Tín Solution được thành lập với đội ngũ nhân sự năng
            động và dày dặn kinh nghiệm, chúng tôi tự tin có thể giúp khách hàng
            giải quyết các vấn đề khó khăn về hệ thống mạng trong thời đại 4.0
            hiện nay. Các lĩnh vực hoạt động chính của Công Ty:
          </p>
          <p className="indent">
            <i class="fa-solid fa-minus"></i>Giải pháp mạng trong các building,
            khách sạn, …
          </p>
          <p className="indent">
            <i class="fa-solid fa-minus"></i>Chuẩn hoá hạ tầng, dịch vụ công
            nghệ thông tin có sẵn của khách hàng
          </p>
          <p className="indent">
            <i class="fa-solid fa-minus"></i>Tư vấn và thiết kế Website.
          </p>
          <p className="indent">
            <i class="fa-solid fa-minus"></i>Dịch vụ lưu trữ Website.
          </p>
          <p className="indent">
            <i class="fa-solid fa-minus"></i>Kinh doanh các dịch vụ trực tuyến,
            quảng bá Website, quảng cáo trực tuyến, …
          </p>
          <p>
            Mỗi doanh nghiệp đều được xây dựng bởi khát vọng cháy bỏng của tất
            cả mọi người: người sáng lập, người lãnh đạo, nhân viên hay bất kỳ
            ai khác đang hàng ngày kề vai sát cánh để tạo lập nên những giá trị
            tốt đẹp cho xã hội. Và chúng tôi tin rằng Bảo Tín Solution sẽ là một
            trong những tập thể đồng hành cùng Khách Hàng để mang đến những giá
            trị khác biệt đó.
          </p>
          <p>
            Với đội ngũ nhân viên và cộng tác viên dày dặn kinh nghiệm, chúng
            tôi tự hào mang đến cho Khách Hàng các dịch vụ tư vấn, thiết kế,
            triển khai cũng như đưa ra các giải pháp tối ưu cho cơ sở hạ tầng hệ
            thống Công Nghệ Thông Tin của Khách Hàng.
          </p>
          <p>
            Song song đó chúng tôi còn có đội ngũ chuyên nghiệp và sáng tạo về
            thiết kế – quảng cáo, Bảo Tín Solution đã, đang và sẽ luôn nỗ lực
            hết mình tạo nên những sản phẩm chuyên nghiệp, chất lượng, đảm bảo
            sẽ giúp Khách Hàng tạo dựng được hình ảnh và phát triển mạnh mẽ.
          </p>
          <p>
            Đến với Bảo Tín Solution là đến với những giá trị đích thực về Công
            Nghệ Thông Tin, là sự lựa chọn nền tảng đúng đắn để phát triển mọi
            tiêu chí của Công Ty.
          </p>
        </div>
      </section>
      <section class="mapouter container" id="about-map">
        <div class="gmap_canvas">
          <iframe
            width="100%"
            height="100%"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=27a%20le%20quy%20don%2C%20nha%20trang&t=&z=15&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
      </section>
      <section className="partner" id="about-partner">
        <h1 className="gradient_text_1">
            Các đơn vị Đối tác
        </h1>
        <div className="partner__img container grid">
            <img src={itech}/>
            <img src={hik}/>
            <img src={xiaodu}/>
            <img src={tuya}/>
            <img src={kb}/>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default About;
