import React, { useState } from "react";

import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";

import "./navbar.css";

import logo from "../../assets/images/logo.png";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const handleNav = () => setToggleMenu(!toggleMenu);

  return (
    // <!-- ----- Header ----- -->
    <header className="header" id="header">
      <nav className="nav">
        <a href="" className="nav_logo">
          <img src={logo} alt="" className="nav_logo-img" />
        </a>
        <div
          className={toggleMenu ? "nav_menu active" : "nav_menu"}
          id="nav-menu"
        >
          <ul className="nav_list">
            <li className="nav_item">
              <NavLink to="/" className="nav_link">
                home
              </NavLink>
            </li>
            <li className="nav_item">
              <NavLink to="/about" className="nav_link">
                Giới thiệu
              </NavLink>
            </li>
            <li className="nav_item">
              <NavLink to="/services" className="nav_link">
                Lĩnh vực hoạt động
              </NavLink>
            </li>
            <li className="nav_item">
              <NavLink to="/outsource" className="nav_link">
                IT Outsource
              </NavLink>
            </li>
            <li className="nav_item">
              <NavLink to="/price" className="nav_link">
                Báo giá dịch vụ
              </NavLink>
            </li>
            <li className="nav_item">
              <NavLink to="/outworks" className="nav_link">
                Out Work
              </NavLink>
            </li>
            {/* <li className="nav_item">
                <a href="demo.html" className="nav_link">
                  Website Demo
                </a>
              </li> */}
            <li className="nav_item">
              <NavLink to="/contact" className="nav_link">
                Liên hệ
              </NavLink>
            </li>
          </ul>
        </div>
        {/* <!-- Toggle btn --> */}
        <div className="hamburger" onClick={handleNav}>
          {!toggleMenu ? (
            <i className="fa-solid fa-code nav_toggle"></i>
          ) : (
            <i className="fa-solid fa-x nav_close"></i>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
