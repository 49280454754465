import React from "react";
import { useState } from "react";
import First__Tab from "../TabComponents/First__Tab";
import Second__Tab from "../TabComponents/Second__Tab";

import "./qualification.css";

const Qualification = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTab1 = () => {
    setActiveTab("tab1");
  };
  const handleTab2 = () => {
    setActiveTab("tab2");
  };

  return (
    <section id="qualification">
      <h5>Qualification</h5>
      <h2>My personal journey</h2>
      <div className="tabs container">
        {/* Tab Nav */}
        <ul className="tab__nav">
          <li
            className={activeTab === "tab1" ? "active" : ""}
            onClick={handleTab1}
          ><i class="fa-solid fa-graduation-cap"></i>
            Education
          </li>
          <li
            className={activeTab === "tab2" ? "active" : ""}
            onClick={handleTab2}
          ><i class="fa-solid fa-briefcase"></i>
            Works
          </li>
        </ul>
        <div className="tab__content">
          {/* Content will be show here */}
          {activeTab === "tab1" ? <First__Tab /> : <Second__Tab />}
        </div>
      </div>
    </section>
  );
};

export default Qualification;
