import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import SubHeading from "../../components/subHeading/SubHeading";

import "./contact.css";

const Contact = () => {
  return (
    <>
    <Navbar/>
      <SubHeading title="Hãy liên hệ với chúng tôi" />
      <section className="contact">
        <h1 className="gradient_text_1" style={{ paddingTop: "6rem" }}>
          Liên hệ với chúng tôi
        </h1>
        <div className="contact__container container grid">
          <div className="contact__options">
            <article className="contact__option">
              <i class="fa-solid fa-envelope"></i>
              <h4>Email</h4>
              <h5>info@baotionsolutions.com</h5>
              <a href="" target="_blank">
                Gửi Email cho chúng tôi
              </a>
            </article>
            <article className="contact__option">
              <i class="fa-solid fa-mobile"></i>
              <h4>Phone</h4>
              <h5>+84 935 989 788</h5>
              <h5>+84 983 379 298</h5>
              <a href="" target="_blank">
                Hãy gọi cho chúng tôi
              </a>
            </article>
            <article className="contact__option">
              <i class="fa-solid fa-message"></i>
              <h4>Gửi tin nhắn</h4>
              <h5>+84 935 989 788</h5>
              <h5>+84 983 379 298</h5>
              <a href="" target="_blank">
                Nhắn tin với chúng tôi qua Zalo
              </a>
            </article>
          </div>
          <form action="">
            <input
              type="text"
              name="name"
              placeholder="Họ và tên"
              required
              id=""
            />
            <input
              type="phone"
              name="phone"
              placeholder="Số điện thoại"
              required
              id=""
            />
            <input
              type="email"
              name="email"
              placeholder="Email của bạn"
              required
              id=""
            />
            <textarea
              type="text"
              name="message"
              rows="7"
              placeholder="Để lại lời nhắn chúng tôi sẽ phản hồi lại ngay "
              required
              id=""
            />
            <button type="submit" className="btn btn-primary">
              Gửi tin nhắn
            </button>
          </form>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default Contact;
